import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as styles from "./project-post.module.css"

const projectPost = ({ data }) => {
  const project = data.markdownRemark
  return (
    <Layout>
      <div className={styles.projectHeader}>
        <h1>{project.frontmatter.title}</h1>
        <p>{project.frontmatter.product}</p>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: project.html }}
        className={styles.projectPost}
      />
    </Layout>
  )
}

export default projectPost

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        product
      }
    }
  }
`
